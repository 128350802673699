//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Container, Row, Col } from "react-bootstrap"
import "./header.scss"
import logo from "./images/fivestarheader.png"

const Header = ({ siteTitle }) => (
  <header>
    <div
      style={{ backgroundColor: "#00163f" }}
      className="mb-3 mb-md-4 mb-lg-5"
    >
      <div>
        <Navbar variant="dark" expand="md">
          <Container>
            <Navbar.Brand
              href="/"
              className="mx-auto mx-md-0"
            >
              <img
                alt=""
                src={logo}
                style={{ maxWidth: "100%" }}
              />
            </Navbar.Brand>
            <Navbar.Text
              className="ml-auto d-none d-md-block text-center"
            >
              <div>
                <a
                  className="h3 text-decoration-none"
                  href="tel:1-808-891-0449"
                  style={{ color: "#5d80c6" }}>
                  808-891-0449
                </a>
                <div>8 AM – 7 PM Pacific</div>
              </div>
            </Navbar.Text>
          </Container>
        </Navbar>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
