//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React from "react"
import { Row, Col, Container, Navbar } from "react-bootstrap"
import logo from "../header/images/fivestarheader.png"
//import './footer.scss'

const Footer = () => (
  <footer className="pt-5">
    <div style={{ backgroundColor: "#041c43" }}>
      <div>
        <Navbar variant="dark" expand="md">
          <Container>
            <div className="mx-auto mx-md-0">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="pr-3">
                  <span className="footer-menu-item mx-auto"><a className="text-white text-decoration-none"
                                                        href="//fivestarreviewsystem.com/terms-of-use-agreement/">Terms Of Use</a></span>
                </div>
                <div className="pr-3 flex-shrink-0 mx-auto">
                  <span className="footer-menu-item"><a className="text-white text-decoration-none"
                                                        href="//fivestarreviewsystem.com/privacy-policy/">Privacy Policy</a></span>
                </div>
                <div className="pr-3 flex-shrink-0 mx-auto">
                  <span className="footer-menu-item"><a className="text-white text-decoration-none"
                                                        href="//fivestarreviewsystem.com/affiliate-program/">Affiliates</a></span>
                </div>
                <div className="flex-shrink-0 mx-auto">
                  <span className="footer-menu-item"><a className="text-white text-decoration-none"
                                                        href="//fivestarreviewsystem.com/refer-a-friend/">Refer-A-Friend</a></span>
                </div>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
    <div style={{ backgroundColor: "#051430" }}>
      <div>
        <Navbar variant="dark" expand="md">
          <Container>
            <div className="mx-auto mx-md-0 text-center">
              <div className="text-light text-muted small">
                Copyright {new Date().getFullYear()} Essential Marketing Systems, Inc. | All Rights Reserved
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
  </footer>
)

export default Footer
