/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Navbar } from "react-bootstrap"

import "normalize.css"
//import "bootstrap/dist/css/bootstrap.min.css"

import Header from "./header/header"
import Footer from "./footer/footer"


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (

    <Container fluid id="page" className="min-vh-100 d-flex flex-column px-0">
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className='flex-grow-1'>
        {/*<div className="mx-auto" >*/}
          <Container fluid className="px-0 container-sm px-1 px-sm-0">
            {children}
          </Container>
        {/*</div>*/}
      </main>
      <Footer />
    </Container>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
